<template>
  <div class="w-100">
    <validation-observer ref="endpointsForm">
      <b-row :key="index" v-for="(item, index) in series" class="mb-2 px-2">
        <b-col>
          <AppCollapse>
            <AppCollapseItem :title="childName(item.name, index)">
              <b-row>
                <b-col lg="6" md="12" sm="12">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <b-form-group label="Name*">
                      <b-form-input
                        autofocus
                        placeholder="John Doe"
                        v-model="item.name"
                        :state="errors[0] ? false : null"
                        class="form-control-merge"
                      ></b-form-input>
                      <b-form-invalid-feedback>Name is required</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col lg="6" md="12" sm="12">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <b-form-group label="XOMES(s)/xTend(s)*">
                      <Multiselect
                        :multiple="true"
                        :hide-selected="true"
                        placeholder="Select an option"
                        v-model="item.xomeIdNames"
                        :options="xomeMultiSelectOptions"
                        group-values="idsNames"
                        group-label="agentType"
                        :show-labels="false"
                        :closeOnSelect="false"
                        label="name"
                        track-by="id"
                      ></Multiselect>
                      <p class="text-danger text-sm" v-if="errors[0]">Please select XOME(s)/xTend(s)</p>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col lg="6" md="12" sm="12">
                  <validation-provider rules="required|urlOrIp" v-slot="{ errors }">
                    <b-form-group label="URL or IP*">
                      <b-form-input
                        autofocus
                        placeholder="URL or IP"
                        v-model="item.target"
                        :state="errors[0] ? false : null"
                        class="form-control-merge"
                      ></b-form-input>
                      <b-form-invalid-feedback v-if="!item.target">URL is required</b-form-invalid-feedback>
                      <b-form-invalid-feedback v-else>URL format is invalid</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col lg="6" md="12" sm="12">
                  <b-form-group label="Measurements*">
                    <MeasurementsSelect :name="name" v-model="item.measurements"></MeasurementsSelect>
                  </b-form-group>
                </b-col>
                <b-button
                  @click="deleteRow(index)"
                  variant="flat-secondary"
                  v-if="index !== 0 || name ==='svcs'"
                >
                  <b-icon icon="trash" />
                </b-button>
              </b-row>
            </AppCollapseItem>
          </AppCollapse>
        </b-col>
      </b-row>
    </validation-observer>
    <b-row>
      <b-button
        @click="addRow"
        class="text-sm ml-1"
        variant="flat-secondary"
      >
        <b-icon icon="plus" />
        <template v-if="name === 'cmpts'"> Add Component</template>
        <template v-else> Add Service</template>
      </b-button>
    </b-row>
  </div>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue';
import Multiselect from 'vue-multiselect';

import MeasurementsSelect from './MeasurementsSelect.vue';

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    MeasurementsSelect,
    Multiselect,
  },
  props: {
    /** Array of objects which are in the following format.
      * ex: {
      *   "agentType": "XOMEs",
      *   "idsNames": [
      *     {
      *       "id": 1,
      *       "name": "example"
      *     }
      *   ]
      * }
      */
    xomes: {
      type: Array,
      required: true,
    },
    /** Refer to the service or component part of the form. It can be "svcs" or "cmpts". */
    name: {
      type: String,
      required: true,
    },
  },
  computed: {
    /** This will check xomes' type incase there was an error from the GET request.
      * If there's an error from the GET request, xomes will be passed as a string.
      * This is a work around of the problem.
      */
    xomeMultiSelectOptions() {
      if ((typeof this.xomes !== 'string') && this.xomes.length > 0) return this.xomes;
      return [];
    },
  },
  mounted() {
    /** Each application must have at least one component. */
    if (this.name === 'cmpts') this.addRow();
  },
  data() {
    return {
      series: [],
    };
  },
  methods: {
    childName(componentName, index) {
      const title = this.name === 'cmpts' ? 'Component' : 'Service';
      if (componentName !== '') {
        return componentName;
      }
      return `${title} ${index + 1}`;
    },
    addRow() {
      this.series.push({
        name: '',
        xomeIdNames: [],
        target: '',
        measurements: {},
      });
      this.$emit('input', this.series);
    },
    deleteRow(index) {
      this.series.splice(index, 1);
      this.$emit('input', this.series);
    },
  },
};
</script>
