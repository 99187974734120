<template>
  <div>
    <b-nav-item-dropdown right toggle-class="d-flex align-items-center dropdown-user-link" class="dropdown-user">
      <template #button-content>
        <b-icon font-scale="1.5" icon="plus-square" />
      </template>
      <b-dropdown-item v-b-modal.addCustomAppModal>
        <b-link>Add Application</b-link>
      </b-dropdown-item>
      <b-dropdown-item v-b-modal.addXomeModalGlobal>
        <b-link>Add XOME</b-link>
      </b-dropdown-item>
    </b-nav-item-dropdown>
    <AddXome modalId="addXomeModalGlobal" @added="onXomeAdded"></AddXome>
    <b-modal id="addCustomAppModal" hide-footer size="lg" title="Add Custom App">
      <AddCustomApp @added="onCustomAppAdded"></AddCustomApp>
    </b-modal>
    <b-modal id="xomeRegistrationModalGlobal" hide-footer title="XOME Registration">
      <XomeRegisterInstructions
        :xomeRegisterInfo="xomeRegisterInfo"
        @finished="closeXomeRegisterInstructions"
      ></XomeRegisterInstructions>
    </b-modal>
  </div>
</template>

<script>
import AddCustomApp from '@/xvisor/components/app/addCustomApp/AddCustomApp.vue';
import AddXome from '@/xvisor/components/xome/AddXome.vue';
import XomeRegisterInstructions from './xome/XomeRegisterInstructions.vue';

export default {
  data() {
    return {
      xomeRegisterInfo: null,
    };
  },
  components: {
    AddCustomApp,
    AddXome,
    XomeRegisterInstructions,
  },
  methods: {
    onXomeAdded(xomeRegisterInfo) {
      this.$bvModal.hide('addXomeModalGlobal');
      this.xomeRegisterInfo = xomeRegisterInfo;
      this.$bvModal.show('xomeRegistrationModalGlobal');
    },
    onCustomAppAdded() {
      this.$bvModal.hide('addCustomAppModal');
    },
    closeXomeRegisterInstructions() {
      this.$bvModal.hide('xomeRegistrationModalGlobal');
    },
  },
};
</script>
