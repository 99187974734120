<template>
  <div>
    <validation-observer ref="trafficBasedForm" @keydown.enter="addApp">
      <b-row class="mb-1">
        <b-col class="font-weight-bold text-lg">Application Name*</b-col>
      </b-row>
      <b-row class="mb-1 px-1">
        <b-col lg="6" md="12" sm="12">
          <validation-provider rules="required" v-slot="{ errors }">
            <b-form-group>
              <b-form-input
                autofocus
                placeholder="Application"
                v-model="app.appName"
                :state="errors[0] ? false : null"
                class="form-control-merge"
              ></b-form-input>
              <b-form-invalid-feedback>Application Name is required</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row class="mb-1 px-2" v-for="(cmpt, indexCmpt) in app.cmpts" :key="indexCmpt">
        <b-col>
          <AppCollapse>
            <AppCollapseItem :title="componentName(cmpt.name, indexCmpt)">
              <b-row class="mb-1">
                <b-col class="font-weight-bold text-lg">Component Name*</b-col>
              </b-row>
              <b-row class="mb-1 px-1">
                <b-col lg="6" md="12" sm="12">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <b-form-group>
                      <b-form-input
                        autofocus
                        placeholder="Component 1"
                        v-model="cmpt.name"
                        :state="errors[0] ? false : null"
                        class="form-control-merge"
                      ></b-form-input>
                      <b-form-invalid-feedback>Component Name is required</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                  <b-button
                    @click="deleteCmpt(indexCmpt)"
                    variant="flat-secondary"
                    v-if="indexCmpt !== 0"
                  >
                    <b-icon icon="trash" />
                  </b-button>
                </b-col>
              </b-row>
              <b-row class="mb-1 px-3" v-for="(pre, indexPre) in cmpt.prefixes" :key="indexPre">
                <b-col>
                  <AppCollapse>
                    <AppCollapseItem :title="addressName(pre.prefix, indexPre)">
                      <b-row>
                        <b-col lg="6" md="12" sm="12">
                          <validation-provider :rules="ipValidation" v-slot="{ errors }">
                            <b-form-group label="Address Prefix*">
                              <b-form-input
                                autofocus
                                placeholder="Ex. 1.2.3.4/16"
                                v-model="pre.prefix"
                                :state="errors[0] ? false : null"
                                class="form-control-merge"
                              ></b-form-input>
                              <b-form-invalid-feedback v-if="!pre.prefix">
                                Address Prefix is required
                              </b-form-invalid-feedback>
                              <b-form-invalid-feedback v-else>Address Prefix format is invalid</b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <b-col lg="6" md="12" sm="12">
                          <validation-provider rules="min_value:0" v-slot="{ errors }">
                            <b-form-group label="VLAN ID">
                              <b-form-input
                                autofocus
                                placeholder="0"
                                type="number"
                                v-model.number="pre.vlanId"
                                :state="errors[0] ? false : null"
                                class="form-control-merge"
                              ></b-form-input>
                              <b-form-invalid-feedback>
                                VLAN ID must be an integer greater than or equal to zero
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <b-col lg="4" md="12" sm="12">
                          <validation-provider :rules="validateMinPort(pre.maxPort)" v-slot="{ errors }">
                            <b-form-group label="Min Port*">
                              <b-form-input
                                autofocus
                                placeholder="Ex. 1000"
                                v-model.number="pre.minPort"
                                :state="errors[0] ? false : null"
                                class="form-control-merge"
                              ></b-form-input>
                              <b-form-invalid-feedback v-if="!pre.minPort">
                                Min Port is required
                              </b-form-invalid-feedback>
                              <b-form-invalid-feedback v-if="pre.minPort < 0">
                                Min Port must have positive value
                              </b-form-invalid-feedback>
                              <b-form-invalid-feedback v-else>
                                Min port must be less than Max Port
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <b-col lg="4" md="12" sm="12">
                          <validation-provider rules="required|max_value:65535|min_value:0" v-slot="{ errors }">
                            <b-form-group label="Max Port*">
                              <b-form-input
                                autofocus
                                placeholder="Ex. 2000"
                                v-model.number="pre.maxPort"
                                :state="errors[0] ? false : null"
                                class="form-control-merge"
                              ></b-form-input>
                              <b-form-invalid-feedback v-if="!pre.minPort">
                                Max Port is required
                              </b-form-invalid-feedback>
                              <b-form-invalid-feedback v-if="pre.maxPort < 0">
                                Max Port must have positive value
                              </b-form-invalid-feedback>
                              <b-form-invalid-feedback v-else>Port must be less than 65535</b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <b-col lg="4" md="12" sm="12">
                          <validation-provider rules="required" v-slot="{ errors }">
                            <b-form-group label="Protocol*">
                              <b-form-select
                                autofocus
                                v-model="pre.proto"
                                :state="errors[0] ? false : null"
                                class="form-control-merge"
                              >
                                <template #first>
                                  <b-form-select-option :value="''" disabled>Select an option</b-form-select-option>
                                </template>

                                <b-form-select-option :value="6">TCP</b-form-select-option>
                                <b-form-select-option :value="17">UDP</b-form-select-option>
                              </b-form-select>
                              <b-form-invalid-feedback>Protocol is required</b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <b-button
                          @click="deletePrefix(indexCmpt, indexPre)"
                          variant="flat-secondary"
                          v-if="indexPre !== 0"
                        >
                          <b-icon icon="trash" />
                        </b-button>
                      </b-row>
                    </AppCollapseItem>
                  </AppCollapse>
                </b-col>
              </b-row>
              <b-button
                @click="addPrefix(indexCmpt)"
                variant="flat-secondary"
                class="ml-1"
              >
                <b-icon icon="plus" />
                <template> Add Address</template>
              </b-button>
            </AppCollapseItem>
          </AppCollapse>
        </b-col>
      </b-row>
      <b-row>
        <b-button
          @click="addCmpt"
          class="ml-1"
          variant="flat-secondary"
        >
          <b-icon icon="plus" />
          <template> Add Component</template>
        </b-button>
      </b-row>
      <b-row align-v="center">
        <b-col lg="6" md="12" sm="12">
          <b-form-checkbox v-model="app.autoDepDetection" class="text-sm custom-control-secondary">
            Automatically detect dependent services
          </b-form-checkbox>
        </b-col>
        <b-col lg="6" md="12" sm="12">
          <b-button @click="addApp" size="small" class="float-right">Add Application</b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue';
import ipUtils from '@/xvisor/utilities/ipUtils';

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
  },
  data() {
    return {
      scrollSettings: {
        maxScrollbarLength: 30,
      },
      app: {
        appName: '',
        autoDepDetection: false,
        cmpts: [{
          name: '',
          prefixes: [{
            prefix: '',
            vlanId: null,
            minPort: '',
            maxPort: '',
            proto: '',
          }],
        }],
      },
    };
  },
  computed: {
    ipValidation() {
      return {
        required: true,
        regex: ipUtils.PREFIX_IP_REGEX,
      };
    },
  },
  methods: {
    componentName(componentName, index) {
      if (componentName !== '') {
        return componentName;
      }
      return `Component ${index + 1}`;
    },
    addressName(addressName, index) {
      if (addressName !== '') {
        return addressName;
      }
      return `Address ${index + 1}`;
    },
    deleteCmpt(index) {
      this.app.cmpts.splice(index, 1);
    },
    addCmpt() {
      this.app.cmpts.push({
        name: '',
        prefixes: [{
          prefix: '',
          vlanId: null,
          minPort: '',
          maxPort: '',
          proto: '',
        }],
      });
    },
    addPrefix(index) {
      this.app.cmpts[index].prefixes.push({
        prefix: '',
        vlanId: null,
        minPort: '',
        maxPort: '',
        proto: '',
      });
    },
    deletePrefix(componentIndex, prefixIndex) {
      this.app.cmpts[componentIndex].prefixes.splice(prefixIndex, 1);
    },
    addApp() {
      this.$refs.trafficBasedForm.validate().then((result) => {
        if (result) {
          this.$http
            .post('/apps/add/trafficbased', this.app)
            .then(() => {
              this.$bvToast.toast('Successfully added application', {
                title: 'Notice',
                variant: 'success',
              });
              this.$emit('added');
            })
            .catch(() => {
              this.$bvToast.toast('Failed to add application', {
                title: 'Notice',
                variant: 'danger',
              });
            });
        }
      });
    },
    validateMinPort(maxPort) {
      return `required|max_value:${maxPort}|min_value:0`;
    },
  },
};
</script>
