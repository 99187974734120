var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"trafficBasedForm",on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addApp.apply(null, arguments)}}},[_c('b-row',{staticClass:"mb-1"},[_c('b-col',{staticClass:"font-weight-bold text-lg"},[_vm._v("Application Name*")])],1),_c('b-row',{staticClass:"mb-1 px-1"},[_c('b-col',{attrs:{"lg":"6","md":"12","sm":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"autofocus":"","placeholder":"Application","state":errors[0] ? false : null},model:{value:(_vm.app.appName),callback:function ($$v) {_vm.$set(_vm.app, "appName", $$v)},expression:"app.appName"}}),_c('b-form-invalid-feedback',[_vm._v("Application Name is required")])],1)]}}])})],1)],1),_vm._l((_vm.app.cmpts),function(cmpt,indexCmpt){return _c('b-row',{key:indexCmpt,staticClass:"mb-1 px-2"},[_c('b-col',[_c('AppCollapse',[_c('AppCollapseItem',{attrs:{"title":_vm.componentName(cmpt.name, indexCmpt)}},[_c('b-row',{staticClass:"mb-1"},[_c('b-col',{staticClass:"font-weight-bold text-lg"},[_vm._v("Component Name*")])],1),_c('b-row',{staticClass:"mb-1 px-1"},[_c('b-col',{attrs:{"lg":"6","md":"12","sm":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"autofocus":"","placeholder":"Component 1","state":errors[0] ? false : null},model:{value:(cmpt.name),callback:function ($$v) {_vm.$set(cmpt, "name", $$v)},expression:"cmpt.name"}}),_c('b-form-invalid-feedback',[_vm._v("Component Name is required")])],1)]}}],null,true)}),(indexCmpt !== 0)?_c('b-button',{attrs:{"variant":"flat-secondary"},on:{"click":function($event){return _vm.deleteCmpt(indexCmpt)}}},[_c('b-icon',{attrs:{"icon":"trash"}})],1):_vm._e()],1)],1),_vm._l((cmpt.prefixes),function(pre,indexPre){return _c('b-row',{key:indexPre,staticClass:"mb-1 px-3"},[_c('b-col',[_c('AppCollapse',[_c('AppCollapseItem',{attrs:{"title":_vm.addressName(pre.prefix, indexPre)}},[_c('b-row',[_c('b-col',{attrs:{"lg":"6","md":"12","sm":"12"}},[_c('validation-provider',{attrs:{"rules":_vm.ipValidation},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Address Prefix*"}},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"autofocus":"","placeholder":"Ex. 1.2.3.4/16","state":errors[0] ? false : null},model:{value:(pre.prefix),callback:function ($$v) {_vm.$set(pre, "prefix", $$v)},expression:"pre.prefix"}}),(!pre.prefix)?_c('b-form-invalid-feedback',[_vm._v(" Address Prefix is required ")]):_c('b-form-invalid-feedback',[_vm._v("Address Prefix format is invalid")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"lg":"6","md":"12","sm":"12"}},[_c('validation-provider',{attrs:{"rules":"min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"VLAN ID"}},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"autofocus":"","placeholder":"0","type":"number","state":errors[0] ? false : null},model:{value:(pre.vlanId),callback:function ($$v) {_vm.$set(pre, "vlanId", _vm._n($$v))},expression:"pre.vlanId"}}),_c('b-form-invalid-feedback',[_vm._v(" VLAN ID must be an integer greater than or equal to zero ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"lg":"4","md":"12","sm":"12"}},[_c('validation-provider',{attrs:{"rules":_vm.validateMinPort(pre.maxPort)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Min Port*"}},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"autofocus":"","placeholder":"Ex. 1000","state":errors[0] ? false : null},model:{value:(pre.minPort),callback:function ($$v) {_vm.$set(pre, "minPort", _vm._n($$v))},expression:"pre.minPort"}}),(!pre.minPort)?_c('b-form-invalid-feedback',[_vm._v(" Min Port is required ")]):_vm._e(),(pre.minPort < 0)?_c('b-form-invalid-feedback',[_vm._v(" Min Port must have positive value ")]):_c('b-form-invalid-feedback',[_vm._v(" Min port must be less than Max Port ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"lg":"4","md":"12","sm":"12"}},[_c('validation-provider',{attrs:{"rules":"required|max_value:65535|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Max Port*"}},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"autofocus":"","placeholder":"Ex. 2000","state":errors[0] ? false : null},model:{value:(pre.maxPort),callback:function ($$v) {_vm.$set(pre, "maxPort", _vm._n($$v))},expression:"pre.maxPort"}}),(!pre.minPort)?_c('b-form-invalid-feedback',[_vm._v(" Max Port is required ")]):_vm._e(),(pre.maxPort < 0)?_c('b-form-invalid-feedback',[_vm._v(" Max Port must have positive value ")]):_c('b-form-invalid-feedback',[_vm._v("Port must be less than 65535")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"lg":"4","md":"12","sm":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Protocol*"}},[_c('b-form-select',{staticClass:"form-control-merge",attrs:{"autofocus":"","state":errors[0] ? false : null},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":'',"disabled":""}},[_vm._v("Select an option")])]},proxy:true}],null,true),model:{value:(pre.proto),callback:function ($$v) {_vm.$set(pre, "proto", $$v)},expression:"pre.proto"}},[_c('b-form-select-option',{attrs:{"value":6}},[_vm._v("TCP")]),_c('b-form-select-option',{attrs:{"value":17}},[_vm._v("UDP")])],1),_c('b-form-invalid-feedback',[_vm._v("Protocol is required")])],1)]}}],null,true)})],1),(indexPre !== 0)?_c('b-button',{attrs:{"variant":"flat-secondary"},on:{"click":function($event){return _vm.deletePrefix(indexCmpt, indexPre)}}},[_c('b-icon',{attrs:{"icon":"trash"}})],1):_vm._e()],1)],1)],1)],1)],1)}),_c('b-button',{staticClass:"ml-1",attrs:{"variant":"flat-secondary"},on:{"click":function($event){return _vm.addPrefix(indexCmpt)}}},[_c('b-icon',{attrs:{"icon":"plus"}}),[_vm._v(" Add Address")]],2)],2)],1)],1)],1)}),_c('b-row',[_c('b-button',{staticClass:"ml-1",attrs:{"variant":"flat-secondary"},on:{"click":_vm.addCmpt}},[_c('b-icon',{attrs:{"icon":"plus"}}),[_vm._v(" Add Component")]],2)],1),_c('b-row',{attrs:{"align-v":"center"}},[_c('b-col',{attrs:{"lg":"6","md":"12","sm":"12"}},[_c('b-form-checkbox',{staticClass:"text-sm custom-control-secondary",model:{value:(_vm.app.autoDepDetection),callback:function ($$v) {_vm.$set(_vm.app, "autoDepDetection", $$v)},expression:"app.autoDepDetection"}},[_vm._v(" Automatically detect dependent services ")])],1),_c('b-col',{attrs:{"lg":"6","md":"12","sm":"12"}},[_c('b-button',{staticClass:"float-right",attrs:{"size":"small"},on:{"click":_vm.addApp}},[_vm._v("Add Application")])],1)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }