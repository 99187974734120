<template>
  <div>
    <b-tabs lazy>
      <b-tab title="Endpoint Based">
        <EndpointBasedApp :xomesUrl="xomesUrl" @added="$emit('added')"></EndpointBasedApp>
      </b-tab>
      <b-tab title="Traffic Based">
        <TrafficBasedApp @added="$emit('added')"></TrafficBasedApp>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import EndpointBasedApp from './EndpointBasedApp.vue';
import TrafficBasedApp from './TrafficBasedApp.vue';

export default {
  components: {
    EndpointBasedApp,
    TrafficBasedApp,
  },
  data() {
    return {
      xomesUrl: '/xomes/idsnames.json',
    };
  },
};
</script>
