<template>
  <validation-observer ref="endpointsForm">
    <validation-provider rules="required" v-slot="{ errors }">
      <Multiselect
        :multiple="true"
        :hide-selected="true"
        placeholder="Select an option"
        v-model="measurementsSelect"
        @input="onChange"
        :options="measurementOptions"
        group-values="group"
        group-label="title"
        :group-select="false"
        :show-labels="false"
        :closeOnSelect="false"
        label="text"
        track-by="text"
        id="measurements-select"
      ></Multiselect>
      <p class="text-danger text-sm" v-if="errors[0]">Measurements are required</p>
    </validation-provider>
  </validation-observer>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
  props: {
    /** Refer to the service or component part of the form. It can be "svcs" or "cmpts". */
    name: {
      type: String,
      required: true,
    },
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      measurementsSelect: [],
      measurementObject: {
        route: false,
        rtd: false,
        jitter: false,
        avbw: false,
        http: false,
        https: false,
        dns: false,
        ldap: false,
      },
    };
  },
  computed: {
    measurementOptions() {
      const options = [
        {
          title: 'Network',
          group: [
            { text: 'Path Route', value: 'route' },
            { text: 'Round Trip Delay', value: 'rtd' },
            { text: 'Packet Delay Variation', value: 'jitter' },
            { text: 'Available Bandwidth', value: 'avbw' },
          ],
        },
      ];
      if (this.name === 'cmpts') {
        options.push({
          title: 'Application',
          group: [
            { text: 'HTTP', value: 'http' },
            { text: 'HTTPS', value: 'https' },
          ],
        });
      } else if (this.name === 'svcs') {
        options.push({
          title: 'Service',
          group: [
            { text: 'DNS', value: 'dns' },
            { text: 'LDAP', value: 'ldap' },
          ],
        });
      }
      return options;
    },
    measurementsSelectValues() {
      return this.measurementsSelect.map((obj) => obj.value);
    },
  },
  methods: {
    onChange() {
      Object.keys(this.measurementObject).forEach((key) => {
        this.measurementObject[key] = this.measurementsSelectValues.includes(key);
      });
      this.$emit('input', this.measurementObject);
    },
  },
};
</script>
