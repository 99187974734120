<template>
  <div class="d-flex justify-content-between align-items-center">
    <div class="d-none d-sm-inline">
      <span class="font-weight-bold mr-1">{{ themeName }} Theme</span>
    </div>
    <b-form-checkbox
      v-model="skin"
      checked="true"
      name="check-button"
      value="light"
      unchecked-value="dark"
      switch
    >
      <span class="switch-icon-left">
        <b-icon icon="sun" />
      </span>
      <span class="switch-icon-right">
        <b-icon icon="moon" />
      </span>
    </b-form-checkbox>
  </div>
</template>

<script>
import { BNavItem } from 'bootstrap-vue';
import useAppCustomizer from '@/layouts/components/app-customizer/useAppCustomizer.js';

export default {
  components: {
    BNavItem,
  },
  setup() {
    const { skin } = useAppCustomizer();
    return { skin };
  },
  computed: {
    themeName() {
      return this.skin === 'dark' ? 'Dark' : 'Light';
    },
  },
};
</script>
