<template>
  <validation-observer ref="endpointsForm" @keydown.enter="addApp">
    <b-row class="mb-1">
      <b-col class="font-weight-bold text-lg">Application Name*</b-col>
    </b-row>
    <b-row class="pl-1">
      <b-col lg="6" md="12" sm="12">
        <validation-provider rules="required" v-slot="{ errors }">
          <b-form-group label="">
            <b-form-input
              autofocus
              placeholder="Application"
              class="inline-block"
              v-model="app.appName"
              :state="errors[0] ? false : null"
            ></b-form-input>
            <b-form-invalid-feedback>Application Name is required</b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <app-collapse>
          <app-collapse-item title="Components">
            <b-row>
              <b-col>
                <EndpointsForm
                  v-model="app.cmpts"
                  class="mt-1"
                  :xomes="xomes"
                  name="cmpts"
                ></EndpointsForm>
              </b-col>
            </b-row>
          </app-collapse-item>
          <app-collapse-item title="Services">
            <b-row>
              <b-col>
                <EndpointsForm
                  v-model="app.svcs"
                  class="mt-0"
                  :xomes="xomes"
                  name="svcs"
                ></EndpointsForm>
              </b-col>
            </b-row>
          </app-collapse-item>
        </app-collapse>
      </b-col>
    </b-row>
    <b-row class="mt-1">
      <b-col>
        <b-button @click="addApp" size="small" class="float-right">Add Application</b-button>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue';
import EndpointsForm from './EndpointsForm.vue';

export default {
  props: {
    xomesUrl: {
      type: String,
      required: true,
    },
  },
  components: {
    AppCollapse,
    AppCollapseItem,
    EndpointsForm,
  },
  data() {
    return {
      xomes: [],
      app: {
        appName: '',
        cmpts: [],
        svcs: [],
      },
    };
  },
  mounted() {
    this.$http
      .get(this.xomesUrl)
      .then((response) => { this.xomes = response.data; });
  },
  methods: {
    addApp() {
      this.$refs.endpointsForm.validate().then((result) => {
        if (result) {
          this.app.cmpts = this.app.cmpts.map((cmpt) => ({
            name: cmpt.name,
            xomeIds: cmpt.xomeIdNames.map((xomeIdName) => xomeIdName.id),
            target: cmpt.target,
            measurements: cmpt.measurements,
          }));
          this.app.svcs = this.app.svcs.map((svc) => ({
            name: svc.name,
            xomeIds: svc.xomeIdNames.map((xomeIdName) => xomeIdName.id),
            target: svc.target,
            measurements: svc.measurements,
          }));
          this.$http
            .post('/apps/add/endptbased', this.app)
            .then(() => {
              this.$bvToast.toast('Successfully added application', {
                title: 'Notice',
                variant: 'success',
              });
              this.$emit('added');
            })
            .catch(() => {
              this.$bvToast.toast('Failed to add application', {
                title: 'Notice',
                variant: 'danger',
              });
            });
        }
      });
    },
  },
};
</script>
