<template>
  <div
    class="navbar-container d-flex content align-items-center"
    style="position: relative;"
  >
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <b-icon icon="list" font-scale="1.6" />
        </b-link>
      </li>
    </ul>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <DarkToggler></DarkToggler>
      <Locale></Locale>
      <!-- TODO: Remove once the replacement "Add" features have been confirmed to work. -->
      <AddDropDown class="nav-margin"></AddDropDown>
      <div
        class="text-right line-height-condensed sm:block nav-margin"
        style="margin-bottom: -0.5rem;"
      >
        <h5>{{ userDisplayName }}</h5>
      </div>
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user nav-margin"
      >
        <template #button-content>
          <b-icon font-scale="2" icon="person" />
        </template>
        <b-dropdown-item to="/profile">
          <b-icon icon="person" />
          <span> Profile</span>
        </b-dropdown-item>
        <b-dropdown-divider />
        <b-dropdown-item href="/logout">
          <b-icon icon="box-arrow-in-right" />
          <span> Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import AddDropDown from '@/xvisor/components/AddDropDown.vue';
import DarkToggler from '@/layouts/components/DarkToggler.vue';
import Locale from '@/layouts/components/Locale.vue';

export default {
  components: {
    AddDropDown,
    DarkToggler,
    Locale,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      userDisplayName: '',
    };
  },
  mounted() {
    this.$http
      .get('/name.json')
      .then((response) => {
        this.userDisplayName = response.data;
      });
  },
  methods: {
    redirectToLogin() {
      this.$router.push('logout');
    },
  },
};
</script>

<style lang="scss">
// margin left spacing for the Add Xome icon.
.nav-margin {
  margin-left: 0.5rem !important;
};

// Decreases navbar height.
.header-navbar {
  min-height: 0 !important;
};

// Shifts the sticky navbar so it meets the left sidebar.
.vertical-layout.vertical-menu-modern.menu-collapsed .navbar.fixed-top {
  left: 50px;
};
</style>
